export const UNAUTHORIZED_ERROR = 401
export const NOT_FOUND_ERROR = 404

export const MAIL_TO_EMAIL = 'support@bookmarkllama.com'

export const CONTACT_PAGE = 'https://www.bookmarkllama.com/contact'

export const FREE_FOLDER_LIMIT = 2
export const ONE_USER_IN_FOLDER = 1
export const ONE_USER_IN_ORGANISATION = 1

export const ONE_USER_TO_ADD = 1
export const ZERO_USER_TO_ADD = 0
export const CREATOR = 1

export const PREMIUM_PRICE = 5
export const ORGANISATION_PRICE = 7

export const CHROME_EXTENSION_URL =
    'https://chrome.google.com/webstore/detail/bookmark-llama/mimpfplfhbocogjgjoiklgefbfkcflph'

export const FIREFOX_EXTENSION_URL =
    'https://addons.mozilla.org/en-GB/firefox/addon/bookmark-llama/'

export const EDGE_EXTENSION_URL =
    'https://microsoftedge.microsoft.com/addons/detail/bookmark-llama/ipnohflnkaljegnpiepbfefnnodmgkmp'

export const userIdsHandSelectedAccountSharing: number[] = [
    3894, 3166, 3527, 953, 3712, 947, 2690, 2441, 5736, 6037, 6070,
]

export const onboardingCreatedDate = new Date('2024-11-06T04:57:36Z')
