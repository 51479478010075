import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { SharedFolder } from '../apiClient'
import apiHelper from '../apiClient/defaultApiClient'
import { AlertType, ButtonTheme, PillType } from '../helpers/constants/enum'
import { NotificationType } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import {
    CHROME_EXTENSION_URL,
    EDGE_EXTENSION_URL,
    FIREFOX_EXTENSION_URL,
} from '../helpers/constants/constants'

import {
    Cog6ToothIcon,
    UserPlusIcon,
    ArrowTopRightOnSquareIcon,
    LockClosedIcon,
} from '@heroicons/react/24/outline'
import { useExtensionState, useOrganisation, useUser } from './Controller'
import Alert from '../helpers/customComponents/Alert'
import {
    formatFreeTrialDate,
    getBrowser,
    handleOpenLiveChat,
    isSupportedBrowser,
    setExtensionInstallOpenedParam,
} from '../helpers/helperFunctions'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import Modal from '../helpers/commonComponents/Modal'
import {
    FirefoxSvgIcon,
    ChromeSvgIcon,
    EdgeSvgIcon,
} from '../helpers/icons/BrowserSvgIcons'
import EncryptionModal from '../helpers/commonComponents/EncryptionModal'
import {
    getBookmarks,
    bookmarks,
    openLocalFolder,
} from '../extensionCommunication/messager'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'

type MergedRemoteAndLocalFolders = SharedFolder & bookmarks

export default function Homepage() {
    const navigate = useNavigate()
    const location = useLocation()
    let [_, setSearchParams] = useSearchParams()
    const { extensionState } = useExtensionState()
    const { organisation } = useOrganisation()
    const { user } = useUser()

    const [sharedFolders, setSharedFolders] = useState<
        MergedRemoteAndLocalFolders[] | []
    >([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [displayInstallExtensionModal, setDisplayInstallExtensionModal] =
        useState<boolean>(false)
    const [openEncryptionModal, setOpenEncryptionModal] =
        useState<boolean>(false)
    const [folderToUnLock, setFolderToUnLock] =
        useState<MergedRemoteAndLocalFolders | null>(null)
    const [browser, setBrowser] = useState<string>('')
    const [showExtensionPollingSpinner, setShowExtensionPollingSpinner] =
        useState<boolean>(false)

    const getNotification = () => {
        // Check if there is a notification after coming back to
        // dashboard and render it on first page load
        const notificationType = location.state?.notification
        if (notificationType) {
            const currentURL = `${location.pathname}${location.search}`
            if (notificationType === NotificationType.sharedFolderSuccess) {
                toastSuccess(uiText.Notifications.success.shareFolder)
            }
            if (notificationType === NotificationType.syncedFolderSuccess) {
                toastSuccess(uiText.Notifications.success.syncFolder)
            }
            if (
                notificationType === NotificationType.stopSharingFolderSuccess
            ) {
                toastSuccess(uiText.Notifications.success.stopSharing)
            }
            navigate(currentURL, { state: undefined })
        } else return
    }

    const mergeLocalAndRemoteBookmarks = (
        bookmarks: bookmarks[],
        sharedFolders: SharedFolder[]
    ) => {
        const mergedFolders: MergedRemoteAndLocalFolders[] = []
        // Merge local and remote bookmarks
        sharedFolders.forEach((folder) => {
            bookmarks.find((bookmark) => {
                if (Number(bookmark.remoteId) === folder.id) {
                    mergedFolders.push({ ...folder, ...bookmark })
                }
            })
        })

        // Get any remaining remote folders that are not in local bookmarks
        const remainingSharedFolders = sharedFolders.filter(
            (folder: SharedFolder) => {
                return !mergedFolders.find(
                    (mergedFolder) => mergedFolder.id === folder.id
                )
            }
        )

        // Map remaining remote folders with local bookmark values
        const mappedRemainingWithBookmarkValues = remainingSharedFolders.map(
            (folder) => {
                return {
                    ...folder,
                    isShared: true,
                    hasPasswordSet: false,
                    localId: '',
                }
            }
        )
        return [...mergedFolders, ...mappedRemainingWithBookmarkValues]
    }

    async function fetchSharedFolders() {
        try {
            let sharedFoldersResponse = await apiHelper.getAllSharedFolders()
            // Navigate or show extension install modal depending on
            //response, extension state and register state
            // const showInstallExtensionModal = true
            // location.state?.register &&

            // we check if the extension is installed

            // setShowInstallExtensionModal(showInstallExtensionModal)

            const navigateToSharePage =
                location.state?.register && !sharedFoldersResponse.length
            // Navigate to share if the user registers and has no shared folders
            if (navigateToSharePage) {
                navigate('/share', {
                    state: { register: location.state.register },
                })
            }
            if (extensionState?.authenticatedUserId) {
                const bookmarks = await getBookmarks()
                const folders = mergeLocalAndRemoteBookmarks(
                    bookmarks,
                    sharedFoldersResponse
                )
                setSharedFolders(folders)
            } else {
                const folders = mergeLocalAndRemoteBookmarks(
                    [],
                    sharedFoldersResponse
                )
                setSharedFolders(folders)
            }
        } catch (error: any) {
            toastError(uiText.Notifications.error.retrieveFolders)
        }
    }

    const getBrowserType = () => {
        try {
            const browser = getBrowser()
            setBrowser(browser)
        } catch (e: any) {
            return
        }
    }

    useEffect(() => {
        getBrowserType()
    }, [])

    useEffect(() => {
        getNotification()
    }, [])

    useEffect(() => {
        fetchSharedFolders()
            .then(() => {
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
        // we need to updated shared folders after the user is
        // authenticated so that we retrieve bookmarks and map
        // them to the shared folders
    }, [extensionState?.authenticatedUserId])

    useEffect(() => {
        const hasRecentlyRegistered = !!location?.state?.register
        const shouldDisplayInstallExtensionModal: boolean =
            sharedFolders.length > 0 &&
            !extensionState?.isInstalled &&
            extensionState !== undefined &&
            hasRecentlyRegistered

        setDisplayInstallExtensionModal(shouldDisplayInstallExtensionModal)
    }, [extensionState?.isInstalled, sharedFolders])

    const organisationFolders = sharedFolders?.filter(
        (folder: MergedRemoteAndLocalFolders) => folder.organisationId
    )

    const folders = sharedFolders
        .filter((folder: MergedRemoteAndLocalFolders) => !folder.organisationId)
        .sort((a, b) => {
            if (a.isPremium && !b.isPremium) return 1
            else if (!a.isPremium && b.isPremium) return -1
            else return 0
        })

    const renderShareFolderCallToAction = () => {
        const hasNoFolders =
            !folders.length && !organisationFolders.length && !isLoading
        return hasNoFolders ? (
            <div
                className={`flex w-full select-none flex-col items-center rounded py-10 px-4 border border-dashed border-slate-400 bg-background
         `}
            >
                <p className="mb-4 select-none text-lg">
                    Share <b>unlimited</b> folders
                </p>
                <Button
                    buttonText="Share a folder"
                    buttonType={ButtonType.button}
                    disabled={false}
                    buttonTheme={ButtonTheme.primary}
                    className="w-full sm:w-auto sm:min-w-min sm:px-20"
                    onClick={() => navigate('share')}
                />
            </div>
        ) : (
            <div className="flex justify-end w-full mt-6">
                <Button
                    buttonText="Share a folder"
                    buttonType={ButtonType.button}
                    disabled={false}
                    buttonTheme={ButtonTheme.primary}
                    className="w-full sm:w-auto sm:min-w-min sm:px-8 border mb-10 sm:mb-0"
                    onClick={() => navigate('share')}
                />
            </div>
        )
    }

    const renderFolderActions = (folder: MergedRemoteAndLocalFolders) => {
        const buttons: JSX.Element[] = []
        if (folder.encrypted && !folder.hasPasswordSet) {
            buttons.push(
                <button
                    key="decrypt-folder"
                    onClick={() => {
                        setFolderToUnLock(folder)
                        setOpenEncryptionModal(true)
                    }}
                    className="rounded-full p-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500"
                >
                    <LockClosedIcon
                        className="h-6 w-6 stroke-slate-700"
                        aria-hidden="true"
                        aria-label="Decrypt folder"
                    />
                </button>
            )
        }
        buttons.push(
            ...[
                <button
                    key="folder-settings"
                    data-testid="settings"
                    onClick={() => navigate(`settings/${folder.id}/members`)}
                    className="rounded-full p-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500 sm:mr-1"
                >
                    <Cog6ToothIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        aria-label="Folder settings"
                    />
                </button>,
            ]
        )

        if (browser !== 'Firefox' && extensionState?.isInstalled) {
            buttons.push(
                <button
                    key="open-folder"
                    data-testid="open-folder"
                    onClick={async () => {
                        // debugger
                        await openLocalFolder(folder.localId)
                    }}
                    className="hidden rounded-full p-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500  sm:flex"
                >
                    <ArrowTopRightOnSquareIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        aria-label="Bookmark folder in browser"
                    />
                </button>
            )
        }

        return <>{...buttons}</>
    }

    // const renderPill = (pill: PillType, pillText: string) => {
    //     return (
    //         <div
    //             className={`mask-text mr-2 max-h-min select-none items-center rounded-xl px-2 text-base
    //      font-bold  ${
    //          pill === PillType.premium && 'bg-purple-50 text-purple-800'
    //      }
    //   ${
    //       pill === PillType.enterprise &&
    //       'max-w-[132px] truncate text-ellipsis bg-green-50 text-green-800'
    //   }
    //   ${
    //       pill === PillType.locked &&
    //       'hidden bg-slate-100 text-slate-900 sm:flex'
    //   }
    //   ${pill === PillType.free && 'bg-amber-50 text-amber-800'}`}
    //         >
    //             {pillText}
    //         </div>
    //     )
    // }

    const folderList = (
        folders: MergedRemoteAndLocalFolders[],
        orgName?: string
    ) => {
        if (folders.length === 0) return null

        // const typePill = (folder: MergedRemoteAndLocalFolders) => {
        //     if (orgName) {
        //         return renderPill(PillType.enterprise, orgName)
        //     } else {
        //         return folder.isPremium
        //             ? renderPill(PillType.premium, 'Premium')
        //             : renderPill(PillType.free, 'Free')
        //     }
        // }

        return (
            <>
                <li className="flex items-center justify-between border-slate-200 pl-4 pr-3 text-lg selection:bg-slate-100 pt-5 pb-4 last:py-4 sm:text-lg md:px-10 sticky top-0 bg-white shadow-md ">
                    <p className="font-bold">Shared folders</p>
                </li>
                {folders.map((folder: MergedRemoteAndLocalFolders) => {
                    return (
                        <li
                            className="flex items-center justify-between border-b border-slate-200 py-3.5 pl-4 pr-3 text-lg selection:bg-slate-100 last:border-b-0 last:py-4 sm:px-6 sm:text-lg md:px-10"
                            key={folder.id}
                        >
                            <p className="mask-text mr-1 w-2/3 truncate text-ellipsis">
                                {folder.name}
                            </p>
                            <div className="flex items-center">
                                {/* {typePill(folder)}
                                {folder.encrypted &&
                                    renderPill(PillType.locked, 'Encrypted')} */}
                                {renderFolderActions(folder)}
                            </div>
                        </li>
                    )
                })}
            </>
        )
    }

    const getExtensionURL = () => {
        if (browser === 'Chrome') {
            return CHROME_EXTENSION_URL
        }
        if (browser === 'Firefox') {
            return FIREFOX_EXTENSION_URL
        }
        if (browser === 'Edge') {
            return EDGE_EXTENSION_URL
        } else return CHROME_EXTENSION_URL
    }

    const isSupported = isSupportedBrowser()
    return (
        <>
            <section className="box-border flex w-full flex-col items-center p-0 pt-10 lg:px-40 lg:pt-14 xl:px-80 min-h-full tall:max-h-[70vh]">
                {isLoading ? (
                    <div className="flex w-full items-center justify-center rounded p-6 shadow">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                ) : (
                    <>
                        {!isSupported && (
                            <Alert
                                alertType={AlertType.failure}
                                alertText={
                                    'Oops, your browser is not supported. The Bookmark Llama extension is available for desktop on Chrome, Firefox and Edge.'
                                }
                                showAlert={!isSupported}
                            />
                        )}
                        {extensionState !== undefined &&
                            !extensionState?.isInstalled &&
                            isSupported && (
                                <Alert
                                    alertType={AlertType.warning}
                                    alertText={
                                        <p className="select-none">
                                            It looks like you don't have&nbsp;
                                            <a
                                                className="cursor-pointer font-bold underline hover:no-underline"
                                                target="_blank"
                                                href={getExtensionURL()}
                                                onClick={() => {
                                                    setExtensionInstallOpenedParam(
                                                        setSearchParams
                                                    )
                                                    setShowExtensionPollingSpinner(
                                                        true
                                                    )
                                                }}
                                            >
                                                the Bookmark Llama extension
                                            </a>
                                            &nbsp;installed. Please install it
                                            to share, sync and get updates on
                                            your folders.
                                        </p>
                                    }
                                    showAlert={!extensionState?.isInstalled}
                                    alertIcon={
                                        showExtensionPollingSpinner ? (
                                            <SpinnerSvgIcon
                                                spinnerColor="yellow-900"
                                                className="fill-yellow-900"
                                                fill="#fefce8"
                                                width="28"
                                                height="28"
                                            />
                                        ) : undefined
                                    }
                                />
                            )}

                        {/* Show free trial banner */}
                        {extensionState &&
                            extensionState?.isInstalled &&
                            isSupported &&
                            user?.freeTrial && (
                                <Alert
                                    alertType={AlertType.success}
                                    alertText={
                                        <p>
                                            Free trial enabled until{' '}
                                            {user?.freeTrial && (
                                                <strong>
                                                    {formatFreeTrialDate(
                                                        user?.freeTrial
                                                    )}
                                                </strong>
                                            )}
                                            ; visit our{' '}
                                            <a
                                                className="cursor-pointer font-bold underline hover:no-underline "
                                                target="_blank"
                                                href="https://bookmarkllama.com/pricing"
                                            >
                                                pricing page
                                            </a>{' '}
                                            for more information. Get in touch
                                            on{' '}
                                            <button
                                                onClick={handleOpenLiveChat}
                                                className="w-fit cursor-pointer font-bold underline hover:no-underline"
                                            >
                                                our live chat
                                            </button>{' '}
                                            to upgrade.
                                        </p>
                                    }
                                    showAlert
                                />
                            )}

                        {/* Folders */}

                        <ul className="mt-6 w-full rounded-xl bg-white shadow overflow-y-scroll dashboard-scrollbar">
                            {folderList(sharedFolders)}
                            {/* {folderList(
                                organisationFolders,
                                organisation?.name
                            )} */}
                        </ul>

                        {renderShareFolderCallToAction()}
                    </>
                )}

                {folderToUnLock && (
                    <EncryptionModal
                        getSharedFolders={fetchSharedFolders}
                        open={openEncryptionModal}
                        setOpen={setOpenEncryptionModal}
                        folderId={folderToUnLock?.id}
                        passwordSalt={folderToUnLock?.passwordSalt}
                    />
                )}
                <Modal
                    open={displayInstallExtensionModal}
                    setOpen={setDisplayInstallExtensionModal}
                    modalTitle={"You've been added to a folder!"}
                    modalText={
                        "You'll need to install the Bookmark Llama extension to sync and share folders."
                    }
                    children={
                        <div className="flex flex-col">
                            <div
                                className={`${
                                    showExtensionPollingSpinner
                                        ? 'visible'
                                        : 'invisible'
                                } flex justify-center items-center mt-2 mb-5`}
                            >
                                <SpinnerSvgIcon width="30" height="30" />
                            </div>
                            <Button
                                buttonType={ButtonType.button}
                                buttonText="Firefox"
                                disabled={isLoading}
                                className="w-full"
                                buttonTheme={ButtonTheme.secondary}
                                buttonIcon={
                                    <FirefoxSvgIcon
                                        height="32"
                                        width="32"
                                        className="mr-2"
                                    />
                                }
                                onClick={() => {
                                    window.open(FIREFOX_EXTENSION_URL)
                                    navigate(location.pathname)
                                    setExtensionInstallOpenedParam(
                                        setSearchParams
                                    )
                                    setShowExtensionPollingSpinner(true)
                                }}
                            />
                            <Button
                                buttonType={ButtonType.button}
                                buttonText="Chrome"
                                buttonTheme={ButtonTheme.secondary}
                                disabled={isLoading}
                                className="mt-4 w-full "
                                buttonIcon={
                                    <ChromeSvgIcon
                                        height="32"
                                        width="32"
                                        className="mr-2"
                                    />
                                }
                                onClick={() => {
                                    window.open(CHROME_EXTENSION_URL)
                                    navigate(location.pathname)
                                    setExtensionInstallOpenedParam(
                                        setSearchParams
                                    )
                                    setShowExtensionPollingSpinner(true)
                                }}
                            />
                            <Button
                                buttonType={ButtonType.button}
                                buttonText="Edge"
                                disabled={isLoading}
                                className="mt-4 w-full"
                                buttonTheme={ButtonTheme.secondary}
                                buttonIcon={
                                    <EdgeSvgIcon
                                        height="32"
                                        width="32"
                                        className="mr-2"
                                    />
                                }
                                onClick={() => {
                                    window.open(EDGE_EXTENSION_URL)
                                    navigate(location.pathname)
                                    setExtensionInstallOpenedParam(
                                        setSearchParams
                                    )
                                    setShowExtensionPollingSpinner(true)
                                }}
                            />
                        </div>
                    }
                />
            </section>
        </>
    )
}
