import { useEffect, useState } from 'react'
import { BookmarkNodeV2 } from '../extensionCommunication/messager'
import { NodeRendererProps, Tree } from 'react-arborist'
import { ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline'
import { extensionState } from '../extensionCommunication/messager'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
    ChromeSvgIcon,
    EdgeSvgIcon,
    FirefoxSvgIcon,
} from '../helpers/icons/BrowserSvgIcons'
import {
    CHROME_EXTENSION_URL,
    EDGE_EXTENSION_URL,
    FIREFOX_EXTENSION_URL,
} from '../helpers/constants/constants'
import {
    formatFreeTrialDate,
    handleOpenLiveChat,
    setExtensionInstallOpenedParam,
} from '../helpers/helperFunctions'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import { useSearchParams } from 'react-router-dom'
import { uiText } from '../uiText/uiText'
import { Tooltip } from 'react-tooltip'
import NoFolderModal from './NoFolderModal'
import NoFoldersVideo from '../assets/no-folders.mp4'

interface BookmarksSectionProps {
    formErrorsLocalFolderId: string | undefined
    bookmarks: null | BookmarkNodeV2[]
    extensionState: extensionState | null
    isLoading: boolean
    localFolderId: string
    handleBookmarkFolderSelect: (localFolderId: string) => void
    sectionTitle?: string
    className?: string
    onRefresh?: any
    freeTrialExpiryDate?: Date | null | undefined
}

export default function BookmarksSection(props: BookmarksSectionProps) {
    const {
        formErrorsLocalFolderId,
        bookmarks,
        extensionState,
        isLoading,
        localFolderId,
        handleBookmarkFolderSelect,
        sectionTitle,
        className,
        freeTrialExpiryDate,
    } = props
    let [_, setSearchParams] = useSearchParams()
    const [showExtensionPollingSpinner, setShowExtensionPollingSpinner] =
        useState<boolean>(false)
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    let hasNoNestedFolders = bookmarks?.every(
        (bookmark) => bookmark.children.length === 0
    )

    if (import.meta.env.MODE === 'e2e') {
        hasNoNestedFolders = false
    }

    // Poll to refresh and set the modal
    useEffect(() => {
        if (hasNoNestedFolders) {
            const intervalId = setInterval(() => {
                props.onRefresh()
            }, 1000)

            return () => clearInterval(intervalId)
        }
    }, [bookmarks])

    function Node({ node, style }: NodeRendererProps<any>) {
        const showTooltip = node.level === 0 && node.data.children.length === 0
        /* This node instance can do many things. See the API reference. */
        return (
            <div
                style={style}
                onClick={() => {
                    node.toggle()
                    if (node.level > 0) handleBookmarkFolderSelect(node.data.id)
                }}
                className={`flex items-center  select-none border-b border-slate-300 h-full   ${
                    node.data.id === localFolderId
                        ? 'bg-indigo-100 hover:bg-indigo-100 font-semibold'
                        : 'hover:bg-indigo-50 '
                } ${
                    showTooltip
                        ? 'hover:cursor-not-allowed text-slate-500'
                        : 'hover:cursor-pointer'
                }`}
            >
                {node.data.children.length > 0 ? (
                    <ChevronRightIcon className="h-6 w-6 stroke-2 mr-4" />
                ) : (
                    <FolderIcon className="h-6 w-6 mr-4 stroke-2" />
                )}
                {node.data.name}
                {showTooltip && (
                    <div className="">
                        <a
                            className="z-40 w-6 h-6 border-2 border-primary rounded-full flex justify-center items-center text-primary font-semibold text-lg cursor-pointer ml-4 "
                            data-tooltip-id="empty-high-level-folder"
                            data-tooltip-place="bottom"
                            data-tooltip-content="Built-in Bookmark Folders are not shareable"
                        >
                            ?
                            <Tooltip id="empty-high-level-folder" />
                        </a>
                    </div>
                )}
            </div>
        )
    }
    const renderBookmarksSection = () => {
        if (!extensionState?.isInstalled) {
            return (
                <>
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            uiText.SharedFolder.missingExtensionAlertText
                        }
                        showAlert
                    />
                    <div
                        className={`${
                            showExtensionPollingSpinner
                                ? 'visible'
                                : 'invisible'
                        } flex justify-center my-10`}
                    >
                        <SpinnerSvgIcon width="40" height="40" />
                    </div>
                    <div className="mt-6 flex flex-col justify-around sm:flex-row">
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Firefox"
                            disabled={isLoading}
                            className="w-full sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <FirefoxSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(FIREFOX_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Chrome"
                            buttonTheme={ButtonTheme.secondary}
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonIcon={
                                <ChromeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(CHROME_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Edge"
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <EdgeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(EDGE_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                    </div>
                </>
            )
        }

        if (!extensionState?.authenticatedUserId) {
            return (
                <div className="flex h-52 w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            )
        }

        if (bookmarks?.length === 0) {
            return (
                <>
                    {props.onRefresh && (
                        <Button
                            buttonType={ButtonType.button}
                            buttonTheme={ButtonTheme.tertiary}
                            buttonIcon={
                                <ArrowPathIcon className="h-6 w-6 flex mr-4 stroke-2 cursor-pointer" />
                            }
                            onClick={props.onRefresh}
                            className="h-8 px-3 mb-2"
                            buttonText="Refresh"
                        ></Button>
                    )}
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            <p>
                                We couldn't locate any bookmark folders on your
                                bookmark bar. Please enable the bookmark bar and
                                ensure the bookmarks you wish to share are
                                added.{' '}
                                <a
                                    href={
                                        'https://www.bookmarkllama.com/blog/show-bookmark-bar?utm_source=webapp_no_shared_folders'
                                    }
                                    target={'_blank'}
                                    className={
                                        'cursor-pointer font-bold underline'
                                    }
                                >
                                    Need help enabling the bookmark bar?
                                </a>
                            </p>
                        }
                        showAlert
                    />
                </>
            )
        }

        if (bookmarks)
            return (
                <>
                    {freeTrialExpiryDate && (
                        <Alert
                            className="mb-8"
                            alertType={AlertType.success}
                            alertText={
                                <p>
                                    Free trial enabled until{' '}
                                    {freeTrialExpiryDate && (
                                        <strong>
                                            {formatFreeTrialDate(
                                                freeTrialExpiryDate
                                            )}
                                        </strong>
                                    )}
                                    ; visit our{' '}
                                    <a
                                        className="cursor-pointer font-bold underline hover:no-underline "
                                        target="_blank"
                                        href="https://bookmarkllama.com/pricing"
                                    >
                                        pricing page
                                    </a>{' '}
                                    for more information. Get in touch on{' '}
                                    <button
                                        onClick={handleOpenLiveChat}
                                        className="w-fit cursor-pointer font-bold underline hover:no-underline"
                                    >
                                        our live chat
                                    </button>{' '}
                                    to upgrade.
                                </p>
                            }
                            showAlert
                        />
                    )}
                    <div className="w-full flex-col justify-center items-center">
                        <div className="flex justify-between">
                            <h2 className="text-lg ml-2 mb-2 font-semibold">
                                Folders
                            </h2>
                            {props.onRefresh && (
                                <Button
                                    buttonType={ButtonType.button}
                                    buttonTheme={ButtonTheme.tertiary}
                                    buttonIcon={
                                        <ArrowPathIcon className="h-6 w-6 flex mr-4 stroke-2 cursor-pointer" />
                                    }
                                    onClick={props.onRefresh}
                                    className="h-8 px-3 mb-2"
                                    buttonText="Refresh"
                                />
                            )}
                        </div>
                        {bookmarks && (
                            <Tree
                                data={bookmarks}
                                openByDefault={true}
                                width={'100%'}
                                height={320}
                                indent={40}
                                rowHeight={64}
                                className="text-lg w-full bookmark-scrollbar border-y border-slate-300"
                            >
                                {Node}
                            </Tree>
                        )}
                    </div>
                </>
            )
        return (
            <div>
                <Alert
                    showAlert
                    alertText="Oops, something went wrong!"
                    alertType={AlertType.failure}
                />
            </div>
        )
    }
    return (
        <div className={className}>
            <Section
                sectionTitle={sectionTitle}
                error={formErrorsLocalFolderId}
                emptySection={
                    bookmarks?.length === 0 || !extensionState?.isInstalled
                }
            >
                <>
                    {renderBookmarksSection()}
                    <NoFolderModal
                        open={hasNoNestedFolders || false}
                        setOpen={() => hasNoNestedFolders}
                        modalTitle="Oops, it looks like you don't have any shareable folders in your Bookmarks Bar yet!"
                        modalText="Watch the video below to learn how to add a folder."
                        children={
                            <video
                                autoPlay={false}
                                controls
                                muted
                                className="rounded-sm shadow-2xl mx-auto"
                            >
                                <source
                                    src={NoFoldersVideo}
                                    type="video/webm"
                                />
                                Your browser does not support this video.
                            </video>
                        }
                    />
                </>
            </Section>
        </div>
    )
}
