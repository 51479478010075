import { useEffect, useState } from 'react'
import Section from '../helpers/commonComponents/Section'
import { CONTACT_PAGE, MAIL_TO_EMAIL } from '../helpers/constants/constants'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import LinkCTA from '../helpers/customComponents/Link'
import { useCurrentUser } from './Profile'
import { User } from '../apiClient'
import apiHelper from '../apiClient/defaultApiClient'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import {
    formatFreeTrialDate,
    handleOpenLiveChat,
} from '../helpers/helperFunctions'

export default function Billing() {
    const [user, setUser] = useState<User | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    // Placeholder, retrieve user for free trial info
    const hasExistingFreeTrial = user?.freeTrial !== undefined

    const freeTrialExpiryDate = user?.freeTrial

    const getUser = async () => {
        let response = await apiHelper.getProfile().catch(async (e) => {
            throw e
        })
        return response as User
    }
    useEffect(() => {
        setIsLoading(true)
        getUser()
            .then(async (u) => {
                setUser(u)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }, [])

    return (
        <>
            {isLoading ? (
                <div className="flex w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            ) : (
                <Section className="box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
                    <>
                        {hasExistingFreeTrial && (
                            <Alert
                                className="mb-8"
                                alertType={AlertType.success}
                                alertText={
                                    <p>
                                        Free trial enabled until{' '}
                                        {freeTrialExpiryDate && (
                                            <strong>
                                                {formatFreeTrialDate(
                                                    freeTrialExpiryDate
                                                )}
                                            </strong>
                                        )}
                                        ; visit our{' '}
                                        <a
                                            className="cursor-pointer font-bold underline hover:no-underline "
                                            target="_blank"
                                            href="https://bookmarkllama.com/pricing"
                                        >
                                            pricing page
                                        </a>{' '}
                                        for more information. Get in touch on{' '}
                                        <button
                                            onClick={handleOpenLiveChat}
                                            className="w-fit cursor-pointer font-bold underline hover:no-underline"
                                        >
                                            our live chat
                                        </button>{' '}
                                        to upgrade.
                                    </p>
                                }
                                showAlert
                            />
                        )}
                        <div>
                            <p className="mb-4 text-center text-lg font-bold md:text-left">
                                Have a billing request?
                            </p>
                            <p className="text-center text-lg md:text-left">
                                Send us an email; we'll get back to you within
                                24hrs regarding any billing requests or queries.
                                You can send an email to&nbsp;
                                <span className="select-text font-semibold">
                                    support@bookmarkllama.com
                                </span>
                                .
                            </p>
                        </div>

                        <div className="flex justify-end">
                            <LinkCTA
                                buttonText="Send billing request"
                                buttonTheme={ButtonTheme.primary}
                                url={CONTACT_PAGE}
                                className="mt-20 w-full md:max-w-max md:px-8"
                            />
                        </div>
                    </>
                </Section>
            )}
        </>
    )
}
