import { ReactElement } from 'react'

export type WizardStepType =
    | WizardStepMultiChoice
    | WizardStepCustom
    | WizardStepEnd

export interface WizardStepMultiChoiceOptionExplicitNextStep {
    title: string
    id: string
    icon: ReactElement
    tooltip?: string
    nextStep: WizardStepType
    onChange?: (value: string) => void
    description?: string
}

export interface WizardStepMultiChoiceOption {
    title: string
    id: string
    icon: ReactElement
    tooltip?: string
    onChange?: (value: string) => void
    description?: string
}

export interface WizardStepMultiChoiceCatchAll {
    id: string
    title: string
    errorMessage: string
    type: 'multi-choice'
    options: WizardStepMultiChoiceOption[]
    nextStep: WizardStepType
    customNextButton?: (
        nextStep: () => void,
        validate: () => Promise<boolean>
    ) => ReactElement
    validate: () => Promise<boolean>
}

interface WizardStepMultiChoiceExplicitNextStep {
    id: string
    title: string
    errorMessage: string
    type: 'multi-choice'
    options: WizardStepMultiChoiceOptionExplicitNextStep[]
}

export type WizardStepMultiChoice =
    | WizardStepMultiChoiceCatchAll
    | WizardStepMultiChoiceExplicitNextStep

export const isWizardStepMultiChoiceCatchAll = (
    step: WizardStepMultiChoice
): step is WizardStepMultiChoiceCatchAll => {
    return (step as WizardStepMultiChoiceCatchAll).nextStep !== undefined
}

export interface WizardStepCustom {
    id: string
    title: string
    type: 'custom'
    component: ReactElement
    nextStep: WizardStepType
    validate?: () => Promise<boolean>
    errorMessage?: string
    customNextButton?: (
        nextStep: () => void,
        validate?: () => Promise<boolean>
    ) => ReactElement
}

export interface WizardStepEnd {
    id: string
    type: 'end'
    finishPage: ReactElement
}
