import { RadioGroup } from '@headlessui/react'
import { ShareType } from '../../helpers/constants/enum'
interface RadioButtonProps {
    options: RadioButtonOption[]
    onChange: (value: string | ShareType) => void
    isLoading: boolean
    value: string | ShareType | null
    label: string
}

export interface RadioButtonOption {
    title: string
    description?: string
    value: string
    icon?: JSX.Element
    toolTip?: {
        id: string
        content: string
        element: JSX.Element
    }
}

export default function RadioButton(props: RadioButtonProps) {
    const { options, onChange, value, isLoading, label } = props
    return (
        <RadioGroup
            value={value || ''}
            onChange={onChange}
            disabled={isLoading}
            className={'w-full mt-9'}
        >
            <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
            <>
                <div className="flex flex-col md:flex-row items-center md:flex-wrap justify-between">
                    {options.map((option) => (
                        <RadioGroup.Option
                            key={option.value}
                            value={option.value}
                            className={({ checked }) =>
                                `my-4 h-[210px] w-full md:w-[48%] cursor-pointer rounded p-6 hover:ring-2 hover:ring-primary active:ring-2 active:ring-primary disabled:cursor-not-allowed  focus:ring-2 focus:ring-primary focus:outline-none  ${
                                    checked
                                        ? 'bg-primary-light/50 ring-2 ring-primary'
                                        : 'bg-background ring-1 ring-slate-300'
                                }
                                    
                                    ${
                                        isLoading &&
                                        'ring-1 hover:cursor-not-allowed hover:ring-1 hover:ring-slate-300 active:ring-1 active:ring-slate-300'
                                    }
                                `
                            }
                        >
                            {({ checked }) => (
                                <div
                                    className={
                                        'flex h-full select-none items-center justify-between'
                                    }
                                >
                                    <div
                                        className={`flex h-full flex-col justify-between  w-full`}
                                    >
                                        <div className="flex items-start md:justify-start justify-center lg:justify-center ">
                                            <div
                                                className={`h-6 min-w-[24px] mt-[2px] rounded-full ${
                                                    checked
                                                        ? 'border-4 border-primary'
                                                        : 'border border-slate-300'
                                                }`}
                                            />
                                            <RadioGroup.Label
                                                as="p"
                                                className={`${
                                                    checked
                                                        ? 'text-primary'
                                                        : ''
                                                } text-xl pl-4 w-full text-left font-semibold`}
                                            >
                                                {option.title}
                                                {option.description && (
                                                    <span className=" block font-light">
                                                        {option.description}
                                                    </span>
                                                )}
                                            </RadioGroup.Label>
                                        </div>

                                        {option.icon && (
                                            <div className="flex w-full justify-center mt-4">
                                                <div
                                                    className={`flex h-full w-14 justify-center icon ${
                                                        checked &&
                                                        'text-primary'
                                                    }`}
                                                >
                                                    {option.icon}
                                                </div>
                                            </div>
                                        )}

                                        {option.toolTip ? (
                                            <a
                                                className="w-8 h-8 border-2 border-primary rounded-full flex justify-center items-center self-end text-primary font-semibold text-xl"
                                                data-tooltip-id={
                                                    option.toolTip.id
                                                }
                                                data-tooltip-place="top"
                                                data-tooltip-content={
                                                    option.toolTip.content
                                                }
                                            >
                                                ?{option.toolTip.element}
                                            </a>
                                        ) : (
                                            <div className="h-4"></div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </>
        </RadioGroup>
    )
}
