import { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import { usePostHog } from 'posthog-js/react'

interface ModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    modalTitle?: string
    modalText?: string
    icon?: JSX.Element
    children?: JSX.Element
    primaryButton?: JSX.Element
    secondaryButton?: JSX.Element
}

export default function Modal(props: ModalProps) {
    const {
        open,
        modalTitle,
        modalText,
        icon,
        children,
        primaryButton,
        secondaryButton,
    } = props
    const cancelButtonRef = useRef(null)

    const posthog = usePostHog()
    useEffect(() => {
        if (open) {
            posthog.capture('frontend:no_folders_modal_displayed')
        }
    }, [open])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[100]"
                initialFocus={cancelButtonRef}
                onClose={() => false}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-8">
                                    <div className="sm:flex sm:items-start">
                                        {icon}

                                        <div className="mt-3 w-full text-center sm:mx-4 sm:mt-0 sm:text-left">
                                            <>
                                                <Dialog.Title
                                                    as="h3"
                                                    className="mb-2 text-2xl"
                                                >
                                                    {modalTitle}
                                                </Dialog.Title>
                                                {modalText && (
                                                    <p className="break-words text-xl mb-4">
                                                        {modalText}
                                                    </p>
                                                )}
                                                {children && children}
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full flex-col bg-slate-50 px-4 py-3 sm:flex-row-reverse sm:items-center sm:px-6">
                                    {primaryButton}
                                    {secondaryButton}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
