import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import posthog, { Properties } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/600-italic.css'
import '@fontsource/montserrat-alternates/500.css'
import '@fontsource/montserrat-alternates/700.css'

import './index.css'

import { router } from './App'
import ErrorPage from './helpers/commonComponents/ErrorPage'
import * as Sentry from '@sentry/react'
import { posthogEnabled, posthogToken, posthogURL } from './helpers/posthog'
import apiHelper from './apiClient/defaultApiClient'
import { Toaster } from 'react-hot-toast'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { MsalProvider } from '@azure/msal-react'
import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

let __COMMIT_HASH__: string = ''
const CommitHash = (window as any).__COMMIT_HASH__ || __COMMIT_HASH__

Sentry.init({
    dsn: 'https://9517dfcda5974645ae20a650956057f6@o1159175.ingest.sentry.io/4505361352097792',
    integrations: [new Sentry.Integrations.Dedupe()],
    maxValueLength: 999999,
    //This value is injected in at build time from vite config
    release: CommitHash,
    environment: import.meta.env.MODE,
    sampleRate: 1.0,

    beforeSend: async (event) => {
        //if not production/staging, don't send anything to sentry
        if (
            import.meta.env.MODE !== 'production' &&
            import.meta.env.MODE !== 'staging'
        ) {
            return null
        }
        return event
    },
})
if (posthogEnabled) {
    apiHelper.getDailyIdentifier().then((uniqueIdentifier) => {
        posthog.init(posthogToken, {
            api_host: posthogURL,
            ip: false,
            persistence: 'memory',
            session_recording: {
                maskTextSelector: '.mask-text',
                maskAllInputs: true,
                maskInputFn: (text, element) => {
                    const maskedText = '*'.repeat(text.length)
                    if (
                        !element ||
                        !element.classList.contains('mask-whitelist')
                    ) {
                        return maskedText
                    }

                    return text
                },
            },
            enable_recording_console_log: false,
            sanitize_properties: (
                properties: Properties,
                event_name: string
            ): Properties => {
                //append user agent to all events
                properties['user_agent'] = navigator.userAgent
                const currentURL = properties['$current_url']
                if (currentURL) {
                    const url = new URL(currentURL as string)
                    const params = url.searchParams
                    const blackListedParams = [
                        'email',
                        'token',
                        'email_address',
                        'reset_token',
                    ]
                    blackListedParams.forEach((blackListParam) => {
                        if (params.has(blackListParam)) {
                            params.set(blackListParam, '*removed*')
                        }
                        for (let key of params.keys()) {
                            if (key.toLowerCase().includes(blackListParam)) {
                                params.set(key, '*removed*')
                            }
                        }
                    })
                    url.search = params.toString()
                    properties['$current_url'] = url.toString()
                }
                return properties
            },
            get_device_id: (uuid): string => {
                return `guest_${uniqueIdentifier.identifier}`
            },
        })
    })
}

document.addEventListener('syncEngineStart', function (e) {
    console.log(`Sync Engine Started with id ${(e as any).detail.id}`)
})

document.addEventListener('syncEngineEnd', function (e) {
    console.log(`Sync Engine Ended with id ${(e as any).detail.id}`)
})

document.addEventListener('onBookmarksChange', function (e) {
    console.log(`Bookmark change detected`)
})

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: '3959c90e-5305-42bf-946c-1b2e440ac661',
        redirectUri: `${window.location.origin}/login`,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    // case LogLevel.Info:
                    //     console.info(message)
                    //     return
                    // case LogLevel.Verbose:
                    //     console.debug(message)
                    //     return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            },
        },
    },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <GoogleOAuthProvider clientId="690568065932-71g94o429qnfbpc3dvmuip13vvcj4ha5.apps.googleusercontent.com">
                <PostHogProvider client={posthog}>
                    <div>
                        <Toaster position="top-right" />
                    </div>
                    <RouterProvider
                        router={router}
                        fallbackElement={<ErrorPage />}
                    />
                </PostHogProvider>
            </GoogleOAuthProvider>
        </MsalProvider>
    </React.StrictMode>
)
