import { useState } from 'react'
import { isWizardStepMultiChoiceCatchAll, WizardStepType } from './types'
import { WizardStep } from './WizardStep'

interface wizardState {
    multiChoiceSelections: Record<string, string | null>
    path: string[]
}

export function WizardController(props: {
    wizardSteps: WizardStepType
    setHasValidationError: (hasError: boolean) => void
    hasValidationError: boolean
    userCreated?: Date
}) {
    const [wizardState, setWizardState] = useState<wizardState>({
        path: [],
        multiChoiceSelections:
            props.wizardSteps.type !== 'multi-choice'
                ? {}
                : {
                      [props.wizardSteps.id]: null,
                  },
    })

    const getCurrentStep = (): WizardStepType => {
        if (wizardState.path.length === 0) {
            //if the path is empty, return the first step
            return props.wizardSteps
        }

        let currentStep: WizardStepType = props.wizardSteps
        mainSearchLoop: for (let i = 0; i < wizardState.path.length; i++) {
            const nextStepID = wizardState.path[i]
            switch (currentStep.type) {
                case 'multi-choice': {
                    if (isWizardStepMultiChoiceCatchAll(currentStep)) {
                        if (currentStep.nextStep.id === nextStepID) {
                            currentStep = currentStep.nextStep
                            continue
                        }
                        currentStep = currentStep.nextStep
                        continue
                    }

                    // Check all options for a matching next step.
                    for (const option of currentStep.options) {
                        if (option.nextStep.id === nextStepID) {
                            currentStep = option.nextStep as WizardStepType
                            continue mainSearchLoop
                        }
                    }

                    //throw an error that search target is not found
                    throw new Error(
                        `WizardController: Step with ID ${nextStepID} not found`
                    )
                }
                case 'custom': {
                    if (currentStep.nextStep.id === nextStepID) {
                        currentStep = currentStep.nextStep
                        continue
                    }
                    throw new Error(
                        `WizardController: Step with ID ${nextStepID} not found`
                    )
                }
                case 'end': {
                    if (currentStep.id === nextStepID) {
                        setWizardState({
                            ...wizardState,
                        })
                        return currentStep
                    }
                    throw new Error(
                        `WizardController: Step with ID ${nextStepID} not found`
                    )
                }
                default:
                    throw new Error(`WizardController: Unknown step type`)
            }
        }
        return currentStep
    }
    const currentStep = getCurrentStep()

    const onNext = async () => {
        switch (currentStep.type) {
            case 'multi-choice': {
                const optionSelected =
                    wizardState.multiChoiceSelections[currentStep.id]

                if (optionSelected === null) {
                    //Render error in UI
                    props.setHasValidationError(true)
                    return
                }

                if (isWizardStepMultiChoiceCatchAll(currentStep)) {
                    setWizardState({
                        path: [...wizardState.path, currentStep.nextStep.id],
                        multiChoiceSelections: {
                            ...wizardState.multiChoiceSelections,
                            [currentStep.nextStep.id]: null,
                        },
                    })
                    return
                }
                const selectedOption = currentStep.options.find(
                    (option: any) => {
                        return option.id === optionSelected
                    }
                )
                if (selectedOption === undefined) {
                    throw new Error(
                        `WizardController: Unable to progress to next step as optionSelected is not found in options`
                    )
                }

                setWizardState({
                    path: [...wizardState.path, selectedOption.nextStep.id],
                    multiChoiceSelections: {
                        ...wizardState.multiChoiceSelections,
                        [selectedOption.nextStep.id]: null,
                    },
                })
                return
            }
            case 'custom': {
                if (currentStep.validate && (await currentStep.validate())) {
                    setWizardState({
                        path: [...wizardState.path, currentStep.nextStep.id],
                        multiChoiceSelections: {
                            ...wizardState.multiChoiceSelections,
                        },
                    })
                    if (props.hasValidationError) {
                        props.setHasValidationError(false)
                    }
                    return
                }
                props.setHasValidationError(true)
                return
            }
        }

        throw new Error(
            `WizardController: Unable to progress to next step as type is not supported ${currentStep.type}`
        )
    }

    const onPrevious = () => {
        //clear validation errors
        props.setHasValidationError(false)
        const newPath = [...wizardState.path]
        newPath.pop()
        setWizardState({
            path: [...newPath],
            multiChoiceSelections: {
                ...wizardState.multiChoiceSelections,
            },
        })
    }

    return (
        <>
            <WizardStep
                wizardStep={currentStep}
                hasError={props.hasValidationError}
                isFirstStep={wizardState.path.length === 0}
                onNext={onNext}
                onPrevious={onPrevious}
                onChange={(value: any) => {
                    if (currentStep.type === 'multi-choice') {
                        props.setHasValidationError(false)
                    }

                    setWizardState({
                        path: [...wizardState.path],
                        multiChoiceSelections: {
                            ...wizardState.multiChoiceSelections,
                            [currentStep.id]: value,
                        },
                    })
                }}
                currentValue={wizardState.multiChoiceSelections[currentStep.id]}
                userCreated={props.userCreated}
            />
        </>
    )
}
