import { useEffect, useState } from 'react'
import {
    getExtensionState,
    bookmarks,
    getBookmarks,
    runSyncEngine,
    shareNewFolder,
    getSyncEngineStats,
} from '../extensionCommunication/messager'
import { ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'

interface extensionInfo {
    version?: string
    authenticatedUserId: number | null
    isInstalled: boolean
}

export function Debug() {
    const extID = 'bhbjefinmpemfkeafpgdgeogdibfmfnl'

    const [sessionToken, setSessionToken] = useState<string | null>(null)
    const [extensionInfo, setExtensionInfo] = useState<extensionInfo | null>(
        null
    )
    const [bookmarks, setBookmarks] = useState<bookmarks[] | null>(null)

    useEffect(() => {
        getExtensionState().then((s) => setExtensionInfo(s))
        const storedSessionToken = localStorage.getItem('sessionToken')
        if (!storedSessionToken || storedSessionToken === '') return
        setSessionToken(storedSessionToken)
        getSyncEngineStats().then((s) => console.log(s))
    }, [])

    return (
        <div className="App flex flex-col items-center text-lg">
            {extensionInfo && (
                <div className="mt-20 mb-8 flex h-40 flex-col justify-around text-center">
                    <h1 className="mb-8 text-2xl font-bold">Extension State</h1>
                    <h2>
                        Authenticated User:&nbsp;
                        <span className="font-bold">
                            {extensionInfo.authenticatedUserId
                                ? extensionInfo.authenticatedUserId
                                : 'null'}
                        </span>
                    </h2>
                    <h2>
                        Version:{' '}
                        <span className="font-bold">
                            {extensionInfo.version}
                        </span>
                    </h2>
                    <h2>
                        Installed:&nbsp;
                        <span className="font-bold">
                            {extensionInfo.isInstalled ? 'true' : 'false'}
                        </span>
                    </h2>
                </div>
            )}
            <div className="flex flex-col justify-between lg:w-1/2 lg:flex-row">
                <Button
                    buttonText={'Get Extension Info'}
                    onClick={async () => {
                        setExtensionInfo(await getExtensionState())
                    }}
                    buttonTheme={ButtonTheme.primary}
                    className="m-2 p-4"
                />
                <Button
                    buttonText={'Run Sync Engine'}
                    onClick={async () => {
                        await runSyncEngine()
                        setBookmarks(await getBookmarks())
                    }}
                    disabled={
                        !extensionInfo?.isInstalled ||
                        !extensionInfo.authenticatedUserId
                    }
                    buttonTheme={ButtonTheme.primary}
                    className="m-2 p-4"
                />
                <Button
                    buttonText={'Get Bookmarks'}
                    onClick={async () => {
                        setBookmarks(await getBookmarks())
                    }}
                    disabled={!extensionInfo?.isInstalled}
                    buttonTheme={ButtonTheme.primary}
                    className="m-2 p-4"
                />
            </div>
            {extensionInfo?.isInstalled && bookmarks && (
                <ul className="my-8 flex flex-col items-center justify-center lg:w-2/3 lg:flex-row lg:flex-wrap">
                    {bookmarks.map((b) => {
                        return (
                            <li key={b.localId}>
                                <Button
                                    buttonText={`Local ID: ${b.localId}, Name: ${b.name}`}
                                    onClick={async () => {
                                        await shareNewFolder(b.localId, null)
                                        setBookmarks(await getBookmarks())
                                    }}
                                    disabled={b.isShared}
                                    buttonTheme={
                                        b.isShared
                                            ? ButtonTheme.sharedDebug
                                            : ButtonTheme.secondary
                                    }
                                    className="m-2 px-4 "
                                />
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}
