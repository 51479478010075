/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserProfile
     */
    orgIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    avatarPath: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    pending: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    freeTrial?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    invitedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    freeTrialStatus?: string;
}

/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isAdmin" in value;
    isInstance = isInstance && "avatarPath" in value;
    isInstance = isInstance && "pending" in value;

    return isInstance;
}

export function UserProfileFromJSON(json: any): UserProfile {
    return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': (new Date(json['created'])),
        'name': json['name'],
        'email': json['email'],
        'id': json['id'],
        'orgIds': !exists(json, 'org_ids') ? undefined : json['org_ids'],
        'isAdmin': json['is_admin'],
        'avatarPath': json['avatar_path'],
        'pending': json['pending'],
        'freeTrial': !exists(json, 'free_trial') ? undefined : (json['free_trial'] === null ? null : new Date(json['free_trial'])),
        'invitedBy': !exists(json, 'invited_by') ? undefined : json['invited_by'],
        'freeTrialStatus': !exists(json, 'free_trial_status') ? undefined : json['free_trial_status'],
    };
}

export function UserProfileToJSON(value?: UserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': (value.created.toISOString()),
        'name': value.name,
        'email': value.email,
        'id': value.id,
        'org_ids': value.orgIds,
        'is_admin': value.isAdmin,
        'avatar_path': value.avatarPath,
        'pending': value.pending,
        'free_trial': value.freeTrial === undefined ? undefined : (value.freeTrial === null ? null : value.freeTrial.toISOString()),
        'invited_by': value.invitedBy,
        'free_trial_status': value.freeTrialStatus,
    };
}

