import Button, { ButtonType } from '../../helpers/customComponents/Button'
import { ButtonTheme } from '../../helpers/constants/enum'
import {
    bookmarks,
    getBookmarks,
    openLocalFolder,
} from '../../extensionCommunication/messager'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CheckCircleIcon,
    QuestionMarkCircleIcon,
    LightBulbIcon,
} from '@heroicons/react/24/outline'

export default function ShareConfirmationScreen(props: {
    invitedEmails: string[]
    folderName: string
    localFolderID: string
}) {
    const [bookmarks, setBookmarks] = useState<null | bookmarks[]>(null)
    const navigate = useNavigate()

    useEffect(() => {
        getBookmarks().then((b) => {
            setBookmarks(b)
        })
    }, [])

    return (
        <>
            <h1 className="text-3xl text-center mt-12 leading-normal ">
                You've successfully shared your folder
                {props.folderName ? `,` : '.'} <br />
                <span className="font-semibold truncate text-ellipsis max-w-[300px] lg:max-w-[600px] inline-block">
                    {props.folderName ? `${props.folderName}` : ''}
                </span>
                <CheckCircleIcon className="w-20 mx-auto mt-0.5 text-primary stroke-[0.75]" />
            </h1>

            <div className="flex flex-col md:flex-row justify-evenly text-xl mt-2">
                <section className="flex flex-col p-0 sm:p-4 lg:p-8 md:w-1/2 lg:w-3/5 xl:w-2/5 2xl:w-1/3 lg:h-full lg:block text-center md:text-left mt-8 md:mt-0">
                    <h2 className="font-bold mb-6 flex justify-center md:justify-start">
                        What happens next?{' '}
                        <QuestionMarkCircleIcon className="ml-3 w-8 text-primary" />
                    </h2>

                    <ol className="list-decimal list-inside w-full leading-7">
                        <li className="pb-8 break-words w-full mask-text">
                            We've just sent an email to everyone you've invited.
                            <Button
                                buttonText="See your invitees"
                                buttonType={ButtonType.button}
                                disabled={false}
                                buttonTheme={ButtonTheme.tertiary}
                                className="text-xl font-semibold hover:bg-indigo-100 mx-auto md:mx-0"
                                onClick={() => {
                                    const sharedFolder = bookmarks?.find(
                                        (b) => b.localId === props.localFolderID
                                    )
                                    if (!sharedFolder) return
                                    navigate(
                                        `/settings/${sharedFolder.remoteId}/members`
                                    )
                                }}
                            />
                        </li>
                        <li>
                            Once they accept your invite, your shared folder
                            will appear in their bookmarks bar.
                        </li>
                    </ol>
                    <p className="mt-10">
                        Did you forget someone?
                        <Button
                            buttonText="Add someone else"
                            buttonType={ButtonType.button}
                            disabled={false}
                            buttonTheme={ButtonTheme.tertiary}
                            className="text-xl font-semibold hover:bg-indigo-100 mx-auto md:mx-0"
                            onClick={() => {
                                const sharedFolder = bookmarks?.find(
                                    (b) => b.localId === props.localFolderID
                                )
                                if (!sharedFolder) return
                                navigate(
                                    `/settings/${sharedFolder.remoteId}/invite`
                                )
                            }}
                        />
                    </p>
                </section>
                <section className="flex flex-col p-0 sm:p-4 lg:p-8 md:w-1/2 lg:w-3/5 xl:w-2/5 2xl:w-1/3 lg:h-full lg:block text-center md:text-left mt-8 md:mt-0 mb-20 md:mb-0">
                    <h2 className="font-bold mb-6 flex justify-center md:justify-start">
                        How does it work?{' '}
                        <LightBulbIcon className="ml-3 w-8 text-primary" />
                    </h2>

                    <p className="w-full leading-7">
                        Your folder is shared and updated directly from your
                        bookmarks bar, and any changes will update the folder
                        for everyone.
                        <Button
                            buttonText="Go to folder"
                            buttonType={ButtonType.button}
                            disabled={false}
                            buttonTheme={ButtonTheme.tertiary}
                            className="text-xl font-semibold hover:bg-indigo-100 mx-auto md:mx-0"
                            onClick={async () => {
                                await openLocalFolder(props.localFolderID)
                            }}
                        />
                    </p>

                    <Button
                        buttonText="Share another folder"
                        buttonType={ButtonType.button}
                        disabled={false}
                        buttonTheme={ButtonTheme.primary}
                        className="px-8 mx-auto md:mx-0 mt-8"
                        onClick={() => {
                            //This is a hacky way to restart the wizard,
                            //we must do this because the wizard doesn't have any url support
                            navigate(0)
                        }}
                    />
                </section>
            </div>
        </>
    )
}
