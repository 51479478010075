import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import RadioButton, { RadioButtonOption } from '../../share/Wizard/RadioButton'
import { Tooltip } from 'react-tooltip'
import { WizardStepMultiChoiceOption, WizardStepType } from './types'

export function WizardStep(props: {
    wizardStep: WizardStepType
    isFirstStep: boolean
    onNext: () => void
    onPrevious: () => void
    onChange: (value: any) => void
    currentValue: any
    hasError: boolean
    userCreated?: Date
}) {
    if (props.wizardStep.type === 'end') {
        return <>{props.wizardStep.finishPage}</>
    }

    let nextButton = (
        <button
            onClick={props.onNext}
            type="button"
            className="flex items-center text-primary font-bold bg-primary-light py-2 px-3 hover:bg-transparent rounded"
        >
            Next
            <ArrowRightIcon className="h-6 w-6 flex ml-4 stroke-2" />
        </button>
    )

    if (
        props.wizardStep.type === 'custom' &&
        props.wizardStep.customNextButton
    ) {
        const step = props.wizardStep

        nextButton = props.wizardStep.customNextButton(
            props.onNext,
            step?.validate
        )
    }

    // if (
    //     props.wizardStep.type === 'multi-choice' &&
    //     props.wizardStep.customNextButton
    // ) {
    //     const step = props.wizardStep
    //     nextButton = props.wizardStep.customNextButton(
    //         props.onNext,
    //         step?.validate
    //     )
    // }

    return (
        <>
            <h2 className="text-2xl font-bold text-center mt-8 sm:mt-12">
                {props.wizardStep.title}
            </h2>
            <div className="flex flex-col justify-center lg:px-32 xl:px-56 2xl:px-80 items-center text-lg w-full">
                <div className="flex flex-col lg:flex-row lg:flex-wrap items-center lg:items-end justify-center w-full">
                    {props.wizardStep.type === 'multi-choice' && (
                        <RadioButton
                            options={MultiChoiceOptionsToRadioButtonOptions(
                                props.wizardStep.options
                            )}
                            onChange={(value: string) => {
                                props.onChange(value)
                            }}
                            label="Would you like to share or sync your bookmarks?"
                            value={props.currentValue}
                            isLoading={false}
                        />
                    )}
                </div>

                {props.wizardStep.type === 'custom' &&
                    props.wizardStep.component}

                {props.hasError ? (
                    <span className="self-start md:ml-4 text-red-800 font-semibold text-lg mt-2.5">
                        {(props.wizardStep as any).errorMessage
                            ? (props.wizardStep as any).errorMessage
                            : 'Please select an option to continue'}
                    </span>
                ) : (
                    <span className="h-[28px] mt-2.5"></span>
                )}
                <div
                    className={`flex ${
                        props.isFirstStep ||
                        props.wizardStep.id === 'bookmarkSelectShare'
                            ? 'justify-end'
                            : 'justify-between'
                    } w-full my-3 sm:flex-row items-center`}
                >
                    {!props.isFirstStep &&
                        props.wizardStep.id !== 'bookmarkSelectShare' && (
                            <button
                                onClick={props.onPrevious}
                                type="button"
                                className="flex items-center font-semibold"
                            >
                                <ArrowLeftIcon className="h-6 w-6 flex mr-4 stroke-2" />
                                Previous
                            </button>
                        )}
                    {nextButton}
                </div>
            </div>
        </>
    )
}

const MultiChoiceOptionsToRadioButtonOptions = (
    option: WizardStepMultiChoiceOption[]
): RadioButtonOption[] => {
    return option.map((option) => {
        return {
            title: option.title,
            value: option.id,
            icon: option.icon,
            description: option.description,
            toolTip: !option.tooltip
                ? undefined
                : {
                      id: option.id,
                      content: option.tooltip,
                      element: <Tooltip id={option.id} />,
                  },
        }
    })
}
