import { InvoicePreview, ResponseError, SharedFolder } from '../../apiClient'
import React, { useEffect, useRef, useState } from 'react'
import apiHelper from '../../apiClient/defaultApiClient'
import Section from './Section'
import Button, { ButtonType } from '../customComponents/Button'
import { ButtonTheme } from '../constants/enum'
import GenericModal from './GenericModal'
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Link from '../customComponents/Link'
import { usePostHog } from 'posthog-js/react'
import { CONTACT_PAGE } from '../constants/constants'

const perUserPerSharedFolderPlanName = 'Premium folder'
const perUserPerOrganisationPlanName = 'Organisation users'

//centsToDollars converts the cent values to dollars and rounds to two decimal places
const centsToDollars = (cents: number): string => {
    let dollars: number = cents / 100
    dollars = Math.round(dollars * 100) / 100
    if (dollars % 1 === 0) {
        // if dollars is an integer
        return dollars.toString()
    } else {
        return dollars.toFixed(2)
    }
}

//newUserCount returns the number of new users that are being added filtering out
//and users that are already added to the folder or organisation
//todo: Filter out currentUser from usersToAdd
const newUserCount = (
    currentUser: string,
    usersToAdd: string[],
    existingUsers: string[]
): number => {
    let newUserCount = getNewUsers(currentUser, usersToAdd, existingUsers)

    if (existingUsers.length === 0) {
        //If there are no existing users, then this is a new folder, and we must add the current user to the count
        return newUserCount.length + 1
    }

    return newUserCount.length
}

export const getNewUsers = (
    currentUser: string,
    usersToAdd: string[],
    existingUsers: string[]
): string[] => {
    //lower-case all emails
    currentUser = currentUser.toLowerCase()
    usersToAdd = usersToAdd.map((i) => i.toLowerCase())
    //filter duplicates from usersToAdd
    usersToAdd = [...new Set(usersToAdd)]
    existingUsers = existingUsers.map((i) => i.toLowerCase())

    //filter out all new emails that already occur in existingUsers
    usersToAdd = usersToAdd.filter((i) => {
        return existingUsers.includes(i) ? null : i
    })

    //filter out the current user from usersToAdd
    return usersToAdd.filter((i) => {
        return i === currentUser ? null : i
    })
}

//isBillingAction contains the logic that detects if a new person is being added to a folder or organisation
//this is exported so that it can be used in other places such as deciding what primary CTA text we should use
//on pages where we display the paymentPreview
export const isBillingAction = (
    currentUser: string,
    usersToAdd: string[],
    existingUsers: string[],
    isOrganisation: boolean
): boolean => {
    const freeUserLimit = 2

    if (usersToAdd.length === 0) {
        //If no users are being added then nothing to bill for
        return false
    }

    const newUsersCount = newUserCount(currentUser, usersToAdd, existingUsers)
    if (isOrganisation) {
        return newUsersCount > 0
    }
    return newUsersCount + existingUsers.length > freeUserLimit
}

//PaymentPreviewDisplay is the controller component that decides what should be rendered if anything
//in almost all use-cases this should be the only component that needs to be imported to display the payment preview
export const PaymentPreviewDisplay = (props: {
    currentUser: string
    organisationId?: number
    sharedFolderId?: SharedFolder
    forceRerender?: number
    currentFolderUserEmails: string[]
    currentOrganisationUserEmails: string[]
    userEmailsToAdd: string[]
}) => {
    //You should avoid calling unsafeSetInvoicePreview directly where possible, instead use setInvoicePreview in the useEffect below
    //This is because we want to ensure that we only set state on the most recent request, and using unsafeSetInvoicePreview directly
    //will lead to inconsistent UI states
    const [invoicePreview, unsafeSetInvoicePreview] = useState<{
        loading: boolean
        invoice?: InvoicePreview
    }>({
        loading: true,
        invoice: undefined,
    })

    const hasShownFreeTrial = useRef<boolean>(false)
    const [displayFreeTrialModal, setDisplayFreeTrialModal] =
        useState<boolean>(false)

    //requestCount allows us to make sure we only setState on the most recent request
    const requestCount = useRef<number>(0)

    let planName = perUserPerSharedFolderPlanName
    if (props.organisationId) {
        planName = perUserPerOrganisationPlanName
    }

    const isUpgrade =
        props.sharedFolderId?.isPremium || props.organisationId !== undefined

    useEffect(() => {
        const currentRequestNumber = ++requestCount.current

        //setInvoicePreview is a safe way to set the State which ensures that we only set the state
        //on the most recent request
        const setInvoicePreview: (preview: typeof invoicePreview) => void = (
            invoicePreview
        ) => {
            const isMostRecentRequest =
                currentRequestNumber === requestCount.current

            if (!isMostRecentRequest) return
            unsafeSetInvoicePreview({
                loading: invoicePreview.loading,
                invoice: invoicePreview.invoice,
            })
        }

        //If organisationId is set then we want to check if we are adding a user to the org
        //if it's unset we want to share if we are adding a user to the folder
        const existingUsers = props.organisationId
            ? props.currentOrganisationUserEmails
            : props.currentFolderUserEmails

        const displayPreview = isBillingAction(
            props.currentUser,
            props.userEmailsToAdd,
            existingUsers,
            props.organisationId !== undefined
        )

        if (!displayPreview) {
            //If we have nothing to display then we just clear the state to ensure
            //no loading screen is displaying and no invoice is displaying
            setInvoicePreview({
                loading: false,
                invoice: undefined,
            })
            return
        }

        //If we have data to display then we turn on the loading screen
        setInvoicePreview({
            loading: true,
            invoice: undefined,
        })

        const totalNewUsers = newUserCount(
            props.currentUser,
            props.userEmailsToAdd,
            existingUsers
        )

        //If we are adding users to a folder and there is only one user in the folder
        //then we don't need to bill for the folder
        if (
            !props.organisationId &&
            (!(totalNewUsers + existingUsers.length > 2) || totalNewUsers <= 0)
        ) {
            setInvoicePreview({
                loading: false,
                invoice: undefined,
            })
            return
        }

        let invoicePreviewPromise: Promise<InvoicePreview>
        if (props.organisationId) {
            invoicePreviewPromise = apiHelper.postOrganisationInvoicePreview({
                organisationId: props.organisationId,
                body: {
                    newUsers: totalNewUsers,
                },
            })
        } else {
            if (props.sharedFolderId && isUpgrade) {
                invoicePreviewPromise =
                    apiHelper.postSharedFolderInvoicePreview({
                        sharedFolderId: props.sharedFolderId.id,
                        body: {
                            newUsers: totalNewUsers,
                        },
                    })
            } else {
                const totalUsers = totalNewUsers + existingUsers.length
                invoicePreviewPromise =
                    apiHelper.postNewSharedFolderInvoicePreview({
                        body: {
                            users: totalUsers,
                        },
                    })
            }
        }

        invoicePreviewPromise
            .then((response) => {
                setInvoicePreview({
                    loading: false,
                    invoice: response,
                })
            })
            .catch((error) => {
                if (error instanceof ResponseError) {
                    if (error.response.status === 400) {
                        setInvoicePreview({
                            loading: false,
                            invoice: undefined,
                        })
                    }
                } else {
                    throw error
                }
            })
    }, [props.userEmailsToAdd, props.forceRerender])

    if (invoicePreview.loading) {
        return (
            <Section>
                <Loader planName={planName} isUpgrade={isUpgrade} />
            </Section>
        )
    }

    if (!invoicePreview.invoice) {
        //invoice is unset, and we aren't loading, so no payment to preview.
        return null
    }

    return (
        <Section>
            <>
                {displayFreeTrialModal && (
                    <FreeTrialModal
                        onExit={() => setDisplayFreeTrialModal(false)}
                    />
                )}
                {invoicePreview.invoice.prorated ? (
                    <UpgradeBillingPlan
                        planName={planName}
                        previewInvoice={invoicePreview.invoice}
                    />
                ) : (
                    <NewBillingPlan
                        planName={planName}
                        previewInvoice={invoicePreview.invoice}
                    />
                )}
            </>
        </Section>
    )
}

//Loader is a typical flashing loader made from copying the structure of NewBillingPlan and UpgradeBillingPlan
const Loader = (props: { planName?: string; isUpgrade: boolean }) => {
    const loadingEffect =
        'animate-pulse flex w-1/4 rounded-full bg-slate-300 h-4 mt-1.5 mb-1.5'

    if (props.isUpgrade) {
        return (
            <>
                <div className="flex w-full items-center justify-between">
                    <h3 className="text-lg font-semibold">{props.planName}</h3>
                </div>

                <div className="flex flex-col items-end pt-4">
                    <div className="flex w-full items-center justify-between text-lg">
                        <p className={loadingEffect}></p>
                        <p className={loadingEffect}></p>
                    </div>
                    <div className="mt-3 w-full border-t border-slate-300 pt-3 flex justify-end"></div>
                    <div className="flex w-full items-center justify-between text-lg">
                        <p className={loadingEffect}></p>
                        <p className={loadingEffect}></p>
                    </div>
                    <div className="flex w-full items-center justify-between text-lg">
                        <p className={loadingEffect}></p>
                        <p className={loadingEffect}></p>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="flex w-full items-center justify-between">
                <h3 className="text-lg font-semibold">{props.planName}</h3>
            </div>
            <div className="flex flex-col items-end pt-4">
                <div className="flex w-full items-center justify-between text-lg">
                    <p className={loadingEffect}></p>
                    <p className={loadingEffect}></p>
                </div>
                <div
                    className={`mt-3 flex w-full items-center justify-between border-t border-slate-300 pt-3 text-lg`}
                >
                    <p className={loadingEffect}></p>
                    <p className={loadingEffect}></p>
                </div>
            </div>
        </>
    )
}

//NewBillingPlan is used for billing plans where the customer is not already paying this
//as a result it doesn't display things like prorated costs and only displays monthly cost
const NewBillingPlan = (props: {
    previewInvoice: InvoicePreview
    planName: string
    freeTrial?: boolean
}) => {
    return (
        <>
            <div className="flex w-full items-center justify-between">
                <h3 className="text-lg font-semibold">{props.planName}</h3>
            </div>
            <div className="flex flex-col items-end pt-4">
                <div className="flex w-full items-center justify-between text-lg">
                    <p>
                        ${centsToDollars(props.previewInvoice.unitPrice)} per
                        user
                    </p>
                    <p>
                        $
                        {centsToDollars(
                            props.previewInvoice.unitPrice *
                                props.previewInvoice.quantity
                        )}
                        &nbsp;
                        <span className="text-base text-slate-500">
                            ({props.previewInvoice.quantity}&nbsp;users)
                        </span>{' '}
                    </p>
                </div>
                <div className="mt-3 flex w-full items-center justify-between border-t border-slate-300 pt-3 text-lg">
                    <p>
                        Total per month
                        {props.previewInvoice.tax && (
                            <span className="text-base text-slate-500">
                                &nbsp;(including{' '}
                                {props.previewInvoice.tax?.percentage}%{' '}
                                {props.previewInvoice.tax?.displayName})
                            </span>
                        )}
                    </p>
                    <p
                        className={
                            props.freeTrial ? 'line-through text-slate-500' : ''
                        }
                    >
                        ${centsToDollars(props.previewInvoice.total)}{' '}
                    </p>
                </div>
                {props.freeTrial && (
                    <div className="flex w-full items-center justify-between text-lg font-semibold">
                        <p></p>
                        <p>14 day free trial</p>
                    </div>
                )}
            </div>
        </>
    )
}

//UpgradeBillingPlan is used for billing plans where the customer is already paying us,
// so we display to them their new monthly cost and their pro rata cost
const UpgradeBillingPlan = (props: {
    previewInvoice: InvoicePreview
    planName: string
    freeTrial?: boolean
}) => {
    return (
        <>
            <div className="flex w-full items-center justify-between">
                <h3 className="text-lg font-semibold">{props.planName}</h3>
            </div>

            <div className="flex flex-col items-end pt-4">
                <div className="flex w-full items-center justify-between text-lg">
                    <p>
                        ${centsToDollars(props.previewInvoice.unitPrice)} per
                        user
                    </p>
                    <p>
                        <span className="text-base text-slate-500">
                            ({props.previewInvoice.quantity}&nbsp;users)
                        </span>{' '}
                        $
                        {centsToDollars(
                            props.previewInvoice.unitPrice *
                                props.previewInvoice.quantity
                        )}
                        &nbsp;
                    </p>
                </div>
                <div className="mt-3 w-full border-t border-slate-300 pt-3 flex justify-end">
                    {props.previewInvoice.tax && (
                        <span className="text-base text-slate-500">
                            &nbsp;(including{' '}
                            {props.previewInvoice.tax?.percentage}%{' '}
                            {props.previewInvoice.tax?.displayName})
                        </span>
                    )}
                </div>
                <div className="flex w-full items-center justify-between text-lg">
                    <p>New total per month</p>
                    <p>
                        $
                        {centsToDollars(
                            props.previewInvoice.total +
                                (props.previewInvoice.tax?.total ?? 0)
                        )}{' '}
                    </p>
                </div>
                {props.previewInvoice.prorated && (
                    <div className="flex w-full items-center justify-between text-lg">
                        <p>Pro rata cost due today</p>
                        <p>
                            $
                            {centsToDollars(
                                props.previewInvoice.prorated.total +
                                    (props.previewInvoice.prorated.tax?.total ??
                                        0)
                            )}{' '}
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export const FreeTrialModal = (props: { onExit: () => void }) => {
    return (
        <GenericModal className="sm:max-w-lg sm:w-full">
            <>
                <header className={'pb-16 select-none'}>
                    <div className={'flex justify-end'}>
                        <p
                            className={
                                'mb-12 text-xl mr-4 mt-4 text-end w-fit font-semibold select-none cursor-pointer hover:text-slate-600'
                            }
                            onClick={props.onExit}
                        >
                            X
                        </p>
                    </div>
                    <h1 className={'text-xl font-semibold text-center'}>
                        Start your 14 day free trial
                    </h1>
                    <h2
                        className={
                            'mb-4 text-lg font-medium text-slate-600 text-center'
                        }
                    >
                        No credit card required
                    </h2>
                </header>
                <section className={'flex flex-col items-center select-none'}>
                    <Button
                        buttonText={'Start free trial'}
                        buttonTheme={ButtonTheme.primary}
                        className="w-fit px-8"
                        onClick={props.onExit}
                    />
                    <p
                        className={
                            'mb-16 mt-4 px-4 text-lg font-medium text-slate-600 text-center'
                        }
                    >
                        Billed at $5 per user monthly after trial
                    </p>
                </section>
            </>
        </GenericModal>
    )
}

export const PaymentPlans = (props: {
    onExit: () => void
    onPlanSelect: () => void
    userId: number | undefined
}) => {
    const posthog = usePostHog()
    useEffect(() => {
        posthog.capture('frontend:payment_plans_displayed')
    }, [])

    return (
        <GenericModal className="sm:w-fit lg:max-w-6xl flex flex-col lg:mx-2">
            <>
                <XMarkIcon
                    className={
                        ' text-xl font-semibold select-none cursor-pointer hover:text-slate-600  h-8 w-8 self-end m-2 rounded-full hover:border hover:bg-slate-50'
                    }
                    onClick={props.onExit}
                />

                <div className="flex flex-col lg:flex-row justify-around md:pb-12 sm:px-4 md:px-8 lg:px-4 xl:px-8">
                    <section className="flex flex-col w-full lg:w-1/3 text-lg">
                        <p className="hidden lg:inline-block text-center  mb-4 font-bold h-7"></p>
                        <article className="p-4">
                            <h1 className="text-4xl">Team</h1>
                            <div>
                                <p className="mt-2 text-slate-600 font-light leading-6 h-14 sm:h-10">
                                    Perfect for small teams looking to share
                                    resources.
                                </p>
                            </div>

                            <p className="mt-4 text-4xl text-primary">
                                $20
                                <span className="text-lg text-default">
                                    {' '}
                                    /month
                                </span>
                            </p>
                            <p className="text-slate-600">Up to 5 people</p>
                            <>
                                <Button
                                    buttonTheme={ButtonTheme.secondary}
                                    buttonText="Start free trial"
                                    onClick={() => {
                                        posthog.capture(
                                            'frontend:start_free_trial',
                                            { type: 'teams' }
                                        )
                                        props.onPlanSelect()
                                    }}
                                    className="w-full mt-12"
                                ></Button>
                                <p className="text-center text-slate-600">
                                    (No credit card required)
                                </p>
                            </>
                        </article>

                        <ul className="mt-8">
                            <li className="bg-primary-light p-4 lg:h-20 leading-6">
                                3 shared folders with unlimited sub-folders
                            </li>
                            <li className=" p-4">6 months of backups</li>
                            <li className="bg-primary-light p-4">
                                Email support
                            </li>
                        </ul>
                    </section>
                    <section className="flex flex-col w-full lg:w-1/3 text-lg lg:border-x mt-8 lg:mt-0">
                        <p className=" hidden lg:inline-block text-center bg-primary-light mb-4 text-primary font-bold h-7">
                            Most popular plan
                        </p>
                        <article className="p-4">
                            <h1 className="text-4xl">Small business</h1>
                            <div>
                                <p className="mt-2 text-slate-600 font-light leading-6 h-14 sm:h-10">
                                    Ideal for small businesses sharing
                                    company-wide resources.
                                </p>
                            </div>

                            <p className="mt-4 text-4xl text-primary">
                                $45
                                <span className="text-lg text-default">
                                    {' '}
                                    /month
                                </span>
                            </p>
                            <p className="text-slate-600">Up to 15 people</p>
                            <>
                                <Button
                                    buttonTheme={ButtonTheme.primary}
                                    buttonText="Start free trial"
                                    onClick={() => {
                                        posthog.capture(
                                            'frontend:start_free_trial',
                                            { type: 'small business' }
                                        )
                                        props.onPlanSelect()
                                    }}
                                    className="w-full mt-12"
                                ></Button>
                                <p className="text-center text-slate-600">
                                    (No credit card required)
                                </p>
                            </>
                        </article>

                        <ul className="mt-8">
                            <li className="bg-primary-light p-4 lg:h-20 leading-6">
                                5 shared folders with unlimited sub-folders
                            </li>
                            <li className=" p-4">1 year of backups</li>
                            <li className="bg-primary-light p-4">
                                Email support
                            </li>
                            <li className=" p-4">Permissions</li>
                        </ul>
                    </section>
                    <section className=" flex flex-col w-full lg:w-1/3 text-lg mt-8 lg:mt-0 pb-4 lg:pb-0">
                        <p className="hidden lg:inline-block text-center mb-4 font-bold h-7"></p>

                        <article className="p-4">
                            <h1 className="text-4xl">Company</h1>
                            <div>
                                <p className="mt-2 text-slate-600 font-light leading-6 h-14 sm:h-10">
                                    More than 30 people? <br />
                                    <a
                                        className="text-primary hover:cursor-pointer underline hover:no-underline font-bold"
                                        href={CONTACT_PAGE}
                                        target="_blank"
                                    >
                                        Get in touch
                                    </a>
                                    .
                                </p>
                            </div>

                            <p className="mt-4 text-4xl text-primary">
                                $75
                                <span className="text-lg text-default">
                                    {' '}
                                    /month
                                </span>
                            </p>
                            <p className="text-slate-600">Up to 30 people</p>

                            <>
                                <Button
                                    buttonTheme={ButtonTheme.secondary}
                                    buttonText="Start free trial"
                                    onClick={() => {
                                        posthog.capture(
                                            'frontend:start_free_trial',
                                            { type: 'company' }
                                        )
                                        props.onPlanSelect()
                                    }}
                                    className="w-full mt-12"
                                ></Button>
                                <p className="text-center text-slate-600">
                                    (No credit card required)
                                </p>
                            </>
                        </article>

                        <ul className="mt-[33px]">
                            <li className="bg-primary-light p-4 lg:h-20 leading-6">
                                Unlimited shared folders and sub-folders
                            </li>
                            <li className=" p-4">2 years of backups</li>
                            <li className="bg-primary-light p-4">
                                Email and phone support
                            </li>
                            <li className=" p-4">Permissions</li>
                        </ul>
                    </section>
                </div>
            </>
        </GenericModal>
    )
}
