import React, { useEffect, useRef, useState } from 'react'
import { PaymentModal } from '../helpers/commonComponents/PaymentModal'
import {
    AlertType,
    ButtonTheme,
    StripeRedirectResponse,
} from '../helpers/constants/enum'
import {
    useCurrentOrganisation,
    useCurrentSharedFolder,
    useFolderUsers,
    useGetCurrentSharedFolder,
    useGetSharedFolderUsers,
} from './Settings'
import Button from '../helpers/customComponents/Button'
import Section from '../helpers/commonComponents/Section'
import apiHelper from '../apiClient/defaultApiClient'
import { ZERO_USER_TO_ADD } from '../helpers/constants/constants'
import {
    GetOrganisationMembers200ResponseInner,
    GetUserProfilePaymentMethods200ResponseInner,
    User,
    PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum as DefaultPermissionsEnum,
} from '../apiClient'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import InviteInput from '../helpers/commonComponents/Invite'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import {
    getNewUsers,
    isBillingAction,
    PaymentPlans,
    PaymentPreviewDisplay,
} from '../helpers/commonComponents/PaymentPreviewDisplay'
import Alert from '../helpers/customComponents/Alert'
import { useNavigate } from 'react-router-dom'
import { onboardingCreatedDate } from '../helpers/constants/constants'
import {
    formatFreeTrialDate,
    handleOpenLiveChat,
} from '../helpers/helperFunctions'

export default function Invite() {
    const [emailAddresses, setEmailAddresses] = useState<string[] | []>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>('')
    const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] =
        useState<boolean>(false)
    const { currentFolder } = useCurrentSharedFolder()
    const { folderUsers } = useFolderUsers()
    const { getSharedFolderUsers } = useGetSharedFolderUsers()
    const [paymentMethod, setPaymentMethod] = useState<
        GetUserProfilePaymentMethods200ResponseInner[] | []
    >([])
    const { getSharedFolder } = useGetCurrentSharedFolder()
    const { organisation } = useCurrentOrganisation()
    const [organisationMembers, setOrganisationMembers] = useState<
        GetOrganisationMembers200ResponseInner[] | []
    >([])
    const navigate = useNavigate()

    const [user, setUser] = useState<User | null>(null)

    const [displayPlanSelector, setDisplayPlanSelector] =
        useState<boolean>(false)

    const paymentPreviewForceRerenderCount = useRef<number>(0)

    useEffect(() => {
        getUser().then(async (u) => {
            setUser(u)
        })
    }, [])

    const isEnterpriseFolder = !!currentFolder?.organisationId

    const hasPaymentMethod = isEnterpriseFolder
        ? true
        : paymentMethod.length > 0

    const requiresPayment = isBillingAction(
        user?.email ?? '',
        emailAddresses ?? [],
        isEnterpriseFolder
            ? organisationMembers.map((m) => m.email)
            : folderUsers.map((m) => m.email),
        isEnterpriseFolder
    )

    const requiresPaymentMethod =
        requiresPayment &&
        !isEnterpriseFolder &&
        !hasPaymentMethod &&
        !currentFolder?.isPremium &&
        !user?.freeTrial

    const freeTrialExpiryDate = user?.freeTrial

    const hasExistingFreeTrial = user?.freeTrial !== undefined
    const userCreatedDate = user?.created || new Date()

    const offerFreeTrial =
        !hasPaymentMethod &&
        !organisation &&
        !hasExistingFreeTrial &&
        requiresPayment &&
        userCreatedDate < onboardingCreatedDate

    const getUser = async () => {
        let response = await apiHelper.getProfile().catch(async (e) => {
            throw e
        })
        return response as User
    }

    const getPaymentMethod = async () => {
        setIsLoading(true)
        const response = await apiHelper
            .getUserProfilePaymentMethods()
            .catch(() => {})
        if (response) {
            setPaymentMethod(response)
            setIsLoading(false)
        }
    }

    const getOrganisationUsers = async (organisationId: number) => {
        const response = await apiHelper
            .getOrganisationMembers({ organisationId: organisationId })
            .catch(() => {
                toastError(
                    uiText.Notifications.error.retrieveOrganisationMembers
                )
            })
        if (response) setOrganisationMembers(response)
    }

    useEffect(() => {
        // todo: check if the folder is in an org
        if (organisation) {
            getOrganisationUsers(organisation.id)
        }
    }, [])

    const getFormValuesFromURL = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const emailAddressesFromURL = queryParams.get('emails')
        const redirectStatus = queryParams.get('redirect_status')
        // Redirect status is set by Stripe
        if (redirectStatus === 'requires_payment_method') {
            toastError(uiText.Notifications.error.addPaymentMethod)
        }
        if (emailAddressesFromURL) {
            const decodedEmailAddresses = emailAddressesFromURL
                ? JSON.parse(decodeURIComponent(emailAddressesFromURL))
                : []
            setEmailAddresses(decodedEmailAddresses)
        }
    }

    useEffect(() => {
        // Handle redirect from Stripe
        const queryParams = new URLSearchParams(window.location.search)
        const redirectStatus = queryParams.get('redirect_status')

        if (emailAddresses.length === ZERO_USER_TO_ADD) {
            getFormValuesFromURL()
            if (
                redirectStatus === StripeRedirectResponse.success &&
                hasPaymentMethod
            ) {
                setShowAddPaymentMethodModal(true)
            }
            setIsLoading(false)
        }
        if (!hasPaymentMethod && redirectStatus === null)
            getPaymentMethod().then(() => {
                setIsLoading(false)
            })
    }, [hasPaymentMethod])

    const postInvites = async () => {
        if (currentFolder) {
            setIsLoading(true)
            apiHelper
                .addSharedFolderAssignments({
                    sharedFolderId: currentFolder.id,
                    body: { emailAddresses: emailAddresses },
                })
                .then(() => {
                    Promise.all([
                        organisation && getOrganisationUsers(organisation.id),
                        getSharedFolderUsers({
                            sharedFolderId: currentFolder.id,
                        }),
                        getSharedFolder({ folderID: currentFolder.id }),
                    ]).then(() => {
                        setIsLoading(false)
                    })
                    setEmailAddresses([])
                    toastSuccess(uiText.Notifications.success.addUsersToFolder)
                })
                .catch(() => {
                    toastError(uiText.Notifications.error.addUsersToFolder)

                    setIsLoading(false)
                })
        } else {
            toastError(uiText.Notifications.error.generic)
        }
    }

    const handleInvites = async () => {
        if (emailAddresses.length === ZERO_USER_TO_ADD) {
            setError("Oops, you'll need to add email addresses to continue")
            return
        }
        // else if (requiresPaymentMethod) {
        //     setShowAddPaymentMethodModal(true)
        //     return
        // }
        else {
            await postInvites()
        }
    }

    const handleInviteOnChange = (_emails: string[]) => {
        setError('')
        setEmailAddresses(_emails)
    }

    let displayPaymentPreview = true

    if (organisation) {
        displayPaymentPreview = !organisation.hasForcedPlan
    } else {
        displayPaymentPreview = !user?.freeTrial
    }

    return (
        <Section className="mt-8 box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-32">
            <>
                {isLoading ? (
                    <div className="flex w-full items-center justify-center">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                ) : (
                    <div className="box-border w-full">
                        <div>
                            {freeTrialExpiryDate && (
                                <Alert
                                    className="mb-8"
                                    alertType={AlertType.success}
                                    alertText={
                                        <p>
                                            Free trial enabled until{' '}
                                            {freeTrialExpiryDate && (
                                                <strong>
                                                    {formatFreeTrialDate(
                                                        freeTrialExpiryDate
                                                    )}
                                                </strong>
                                            )}
                                            ; visit our{' '}
                                            <a
                                                className="cursor-pointer font-bold underline hover:no-underline "
                                                target="_blank"
                                                href="https://bookmarkllama.com/pricing"
                                            >
                                                pricing page
                                            </a>{' '}
                                            for more information. Get in touch
                                            on{' '}
                                            <button
                                                onClick={handleOpenLiveChat}
                                                className="w-fit cursor-pointer font-bold underline hover:no-underline"
                                            >
                                                our live chat
                                            </button>{' '}
                                            to upgrade.
                                        </p>
                                    }
                                    showAlert
                                />
                            )}
                            <p className="mb-4 text-lg font-bold ">
                                Invite by email
                            </p>

                            <InviteInput
                                isLoading={isLoading}
                                handleInviteOnChange={handleInviteOnChange}
                                emailAddresses={emailAddresses}
                            />
                            {/* {user?.freeTrial && (
                                <p className="  italic mt-2 ml-2">
                                    Free trial enabled
                                </p>
                            )} */}
                            <p className="ml-2 mt-1 h-2 font-medium text-red-800">
                                {error ? error : ''}
                            </p>

                            {/* {displayPaymentPreview &&
                                !offerFreeTrial &&
                                requiresPayment && (
                                    <PaymentPreviewDisplay
                                        currentUser={user?.email ?? ''}
                                        organisationId={
                                            currentFolder?.organisationId
                                        }
                                        sharedFolderId={
                                            currentFolder ?? undefined
                                        }
                                        userEmailsToAdd={emailAddresses}
                                        currentFolderUserEmails={folderUsers.map(
                                            (u) => u.email
                                        )}
                                        currentOrganisationUserEmails={organisationMembers.map(
                                            (u) => u.email
                                        )}
                                        forceRerender={
                                            paymentPreviewForceRerenderCount.current
                                        }
                                    />
                                )} */}

                            <div className="flex flex-col md:items-end">
                                <Button
                                    disabled={isLoading}
                                    buttonText={
                                        // offerFreeTrial
                                        //     ? 'Select Plan'
                                        //     : 'Add to folder'
                                        'Add to folder'
                                    }
                                    buttonTheme={ButtonTheme.primary}
                                    className="mt-20 px-8"
                                    onClick={async () => {
                                        // if (offerFreeTrial) {
                                        //     setDisplayPlanSelector(true)
                                        // } else {
                                        //     await handleInvites()
                                        // }
                                        await handleInvites()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {displayPlanSelector && (
                    <PaymentPlans
                        onExit={() => setDisplayPlanSelector(false)}
                        onPlanSelect={async () => {
                            await apiHelper.postFreeTrial()
                            //refresh user object, to get freeTrial state
                            const u = await getUser()
                            setUser(u)
                            setDisplayPlanSelector(false)
                        }}
                        userId={user?.id}
                    />
                )}

                {/*<PaymentModal*/}
                {/*    open={showAddPaymentMethodModal}*/}
                {/*    setOpen={setShowAddPaymentMethodModal}*/}
                {/*    emailAddresses={newEmailAddresses}*/}
                {/*    hasPaymentMethod={hasPaymentMethod}*/}
                {/*    getPaymentMethod={getPaymentMethod}*/}
                {/*    onClose={() => {*/}
                {/*        paymentPreviewForceRerenderCount.current++*/}
                {/*    }}*/}
                {/*/>*/}
            </>
        </Section>
    )
}
