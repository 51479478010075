import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import apiHelper from '../apiClient/defaultApiClient'
import { ResponseError, SignupRequest } from '../apiClient'
import { ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import marketingImage from '../assets/login.png'
import hasSessionToken from '../helpers/hooks/hasSessionToken'
import { Switch } from '@headlessui/react'
import { uiText } from '../uiText/uiText'
import { toastError } from '../helpers/commonComponents/toastHelper'
import Input from '../helpers/customComponents/Input'
import InputPassword from '../helpers/customComponents/InputPassword'
import {
    GoogleIcon,
    MicrosoftIcon,
} from '../helpers/icons/ThirdPartySignInIcons'
import Modal from '../helpers/commonComponents/Modal'
import {
    GoogleLogin,
    useGoogleLogin,
    useGoogleOneTapLogin,
} from '@react-oauth/google'
import { useMsal } from '@azure/msal-react'

interface RegistrationFormInputs {
    name: string
    email: string
    password: string
}

export default function Register() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegistrationFormInputs>()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [enabled, setEnabled] = useState(true)
    const { instance } = useMsal()

    const [searchParams] = useSearchParams()
    let navigate = useNavigate()

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setIsLoading(true)
            const inviteCode = searchParams.get('invite_code') ?? undefined
            apiHelper
                .postLoginGoogleRaw({
                    body: {
                        code: codeResponse.code,
                        scope: codeResponse.scope,
                        marketingConsent: enabled,
                        inviteCode: inviteCode,
                    },
                })
                .then(async (data) => {
                    const resp = await data.raw.json()
                    localStorage.setItem('sessionToken', resp.session_token)
                    //response code 201 means we had to register the user
                    navigate('/', {
                        state: { register: data.raw.status === 201 },
                    })
                })
        },
        flow: 'auth-code',
    })

    const postSignUp = async (data: SignupRequest) => {
        setIsLoading(true)
        const inviteCode = searchParams.get('invite_code') ?? undefined
        const skipStripe =
            searchParams.get('skip_stripe') === 'true' ? true : undefined

        await apiHelper
            .signup({
                body: {
                    ...data,
                    inviteCode: inviteCode,
                    marketingEmailConsent: enabled,
                    skipStripe: skipStripe,
                },
            })
            .then((data) => {
                localStorage.setItem('sessionToken', data.sessionToken)
                navigate('/', { state: { register: true } })
            })
            .catch(async (error) => {
                if (error instanceof ResponseError) {
                    await error.response.json().then((res) => {
                        toastError(
                            res.message === 'email address already in-use'
                                ? uiText.Notifications.error.emailAlreadyInUse
                                : uiText.Notifications.error.generic
                        )
                    })
                    setIsLoading(false)
                }
            })
    }

    const onSubmit: SubmitHandler<RegistrationFormInputs> = (data) => {
        postSignUp({ ...data, marketingEmailConsent: false })
    }

    if (hasSessionToken()) return <Navigate to="/" replace />
    else
        return (
            <div className="h-screen">
                <div className="md:bg-gradient-to-r from-background to-50% to-indigo-50 from-50%  min-h-full">
                    <BookmarkLlamaDesktopIcon className={'mx-4 pt-4 lg:mx-5'} />
                    <div className="flex justify-center  ">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="px-4 h-full w-full md:h-2/3 rounded md:shadow-lg md:border bg-background md:w-4/5 xl:w-2/3 md:p-4 md:my-8"
                        >
                            <h1 className="text-3xl font-medium mt-14 text-center">
                                Hey there, let's set you up!
                            </h1>

                            <div className="flex flex-col justify-center items-center  lg:flex-row-reverse lg:items-start mt-16">
                                <div className="w-72 sm:w-96 lg:w-80 2xl:w-96 mx-auto lg:mx-0 mt-2">
                                    <Button
                                        buttonText={'Continue with Google'}
                                        disabled={isLoading}
                                        buttonType={ButtonType.button}
                                        className="w-72 sm:w-96 lg:w-80 2xl:w-96 text-default"
                                        buttonTheme={ButtonTheme.secondaryDark}
                                        buttonIcon={<GoogleIcon />}
                                        onClick={loginGoogle}
                                    />

                                    <Button
                                        buttonText={'Continue with Microsoft'}
                                        disabled={isLoading}
                                        buttonType={ButtonType.button}
                                        className=" text-default mt-4 w-72 sm:w-96 lg:w-80 2xl:w-96"
                                        buttonTheme={ButtonTheme.secondaryDark}
                                        buttonIcon={<MicrosoftIcon />}
                                        onClick={() =>
                                            instance
                                                .loginPopup({
                                                    scopes: ['User.Read'],
                                                })
                                                .catch((e) => {
                                                    console.log(e)
                                                })
                                                .then((res) => {
                                                    if (!res) return
                                                    setIsLoading(true)
                                                    apiHelper
                                                        .postLoginMicrosoftRaw({
                                                            body: {
                                                                code: res.accessToken,
                                                            },
                                                        })
                                                        .then(async (data) => {
                                                            const resp =
                                                                await data.raw.json()
                                                            localStorage.setItem(
                                                                'sessionToken',
                                                                resp.session_token
                                                            )
                                                            //response code 201 means we had to register the user
                                                            navigate('/', {
                                                                state: {
                                                                    register:
                                                                        data.raw
                                                                            .status ===
                                                                        201,
                                                                },
                                                            })
                                                        })
                                                })
                                        }
                                    />
                                </div>
                                <p className="font-semibold text-lg text-center my-6 lg:mx-10 lg:my-12">
                                    or
                                </p>

                                <div className="flex flex-col items-center h-full">
                                    {/* Name */}
                                    <Input
                                        labelText="Name*"
                                        register={register('name', {
                                            required: true,
                                        })}
                                        errors={errors.name}
                                        isLoading={isLoading}
                                        inputType="register-name"
                                        errorText="This field is required"
                                        className="w-72 sm:w-96 lg:w-80 2xl:w-96"
                                    />
                                    {/* Email */}
                                    <Input
                                        labelText="Email*"
                                        register={register('email', {
                                            required: true,
                                        })}
                                        errors={errors.email}
                                        isLoading={isLoading}
                                        inputType="login-email"
                                        errorText="This field is required"
                                        className="w-72 sm:w-96 lg:w-80 2xl:w-96"
                                    />

                                    {/* Password */}
                                    <InputPassword
                                        labelText="Password*"
                                        register={{
                                            ...register('password', {
                                                required: true,
                                                maxLength: 72,
                                            }),
                                        }}
                                        showPasswordIconClass="bottom-[51%] right-[6%] "
                                        inputClass={
                                            'w-72 sm:w-96 lg:w-80 2xl:w-96'
                                        }
                                        errors={errors.password}
                                        isLoading={isLoading}
                                        inputType="register-password"
                                        customElement={
                                            <>
                                                {errors.password &&
                                                    errors.password?.type !==
                                                        'maxLength' && (
                                                        <span
                                                            role="alert"
                                                            className="block font-semibold text-red-800 selection:bg-none"
                                                        >
                                                            This field is
                                                            required
                                                        </span>
                                                    )}
                                                {!errors.password && (
                                                    <span className="text-slate-600 font-semibold">
                                                        Must be at least 6
                                                        characters long
                                                    </span>
                                                )}
                                            </>
                                        }
                                    />
                                    <Button
                                        buttonText={'Create account'}
                                        disabled={isLoading}
                                        buttonType={ButtonType.submit}
                                        className="my-8 w-72 sm:w-96 lg:w-80 2xl:w-96"
                                        buttonTheme={ButtonTheme.primary}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col justify-center">
                                <Switch.Group>
                                    <div className="mt-4 flex  items-center self-center  sm:text-lg lg:mt-20">
                                        <Switch
                                            checked={enabled}
                                            onChange={setEnabled}
                                            className={`${
                                                enabled
                                                    ? 'bg-primary'
                                                    : 'bg-gray-300'
                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                            disabled={isLoading}
                                        >
                                            <span className="sr-only">
                                                Opt-in for marketing emails
                                            </span>

                                            <span
                                                className={`${
                                                    enabled
                                                        ? 'translate-x-5 sm:translate-x-6 '
                                                        : 'translate-x-1'
                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                        <Switch.Label className="selection-none ml-2 cursor-pointer rounded-md p-[2px] font-semibold hover:bg-primary-light">
                                            Subscribe to product news & updates
                                        </Switch.Label>
                                    </div>
                                </Switch.Group>
                                <Link
                                    to={'/login'}
                                    className="self-center inline-block text-center rounded-md px-2 ring-primary hover:bg-primary-light focus-visible:rounded-sm  focus-visible:outline-none focus-visible:ring-2 mb-16 sm:text-lg "
                                >
                                    <span className="font-semibold">
                                        Already have an account?&nbsp;
                                        <span className="font-bold text-primary underline">
                                            Log in
                                        </span>
                                    </span>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
}
